import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {LogoWithText} from '@/componentLibrary/components/Logo/LogoWithText';
import {H2, P1} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSuccessPageLogic} from './logic';
import messages from './messages';
import {
    Button,
    Content,
    ContentWrapper,
    CustomIcon,
    HeaderWrapper,
    Title,
    Wrapper
} from './styled';
import {Props} from './types';

import {ReactSVG} from 'react-svg';
export function SuccessPage({organizationId, collectedFromLinkedin}: Props) {
    const phrases = useExtractPhraseConstants(messages);
    const goToJobApplications = useSuccessPageLogic(organizationId);
    const {user} = useLoggedInUser();

    return (
        <Wrapper>
            <HeaderWrapper>
                <LogoWithText />
                <LanguageToggle userId={user?.id} fullWidth={false} />
            </HeaderWrapper>
            <ContentWrapper>
                <Content>
                    <Title>
                        <CustomIcon>
                            <ReactSVG src={'/icons/custom/processing.svg'} />
                        </CustomIcon>
                        {collectedFromLinkedin && <H2>{phrases.linkedInDataTitle}</H2>}
                    </Title>
                    <P1>{phrases.description}</P1>
                    <Button style={{width: 'fit-content'}} onClick={goToJobApplications}>
                        {phrases.continueToTests}
                    </Button>
                </Content>
            </ContentWrapper>
        </Wrapper>
    );
}
